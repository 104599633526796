import React, { useContext, useEffect, useState } from 'react'
import { PostTenant, Subscription } from '../../Interfaces/Types'
import Modal from '@embracesbs/component-modal'
import TextFieldWrapper from '@embracesbs/component-textfieldwrapper/TextFieldWrapper'
import TextField from '@embracesbs/component-textfield/TextField'
import Container from '@embracesbs/component-container/Container'
import { ConfigContext } from '../Config/ConfigContext'
import Autocomplete from '@embracesbs/component-autocomplete'
import callApiWithToken from '../../Utilities/ApiService/ApiService'

interface AddTenantProps {
  IsOpen: boolean
  Subscription?: Subscription
  OnClosed: () => void
  OnCreated: (tenant?: any) => void
}

interface EnviromentOptions {
  label: string
  value: string
}

const AddTenant: React.FC<AddTenantProps> = ({ IsOpen, OnClosed, Subscription, OnCreated }) => {
  const config = useContext(ConfigContext)

  const [tenantName, setTenantName] = useState<string>('')
  const [tenantDisplayName, setTenantDisplayName] = useState<string>('')
  const [environment, setEnvironment] = useState<string>('')

  const [environmentOptions, setEnvironmentOptions] = useState<EnviromentOptions[]>([])

  useEffect(() => {
    if (config && config.environments) {
      const options: EnviromentOptions[] = config.environments.map((environment) => ({
        label: environment,
        value: environment
      }))
      setEnvironmentOptions(options)
    }
  }, [config])

  const handleAddTenant = () => {
    //Later some better validation
    if (tenantName === '') {
      alert('please add tenantName')
      return
    }

    if (tenantDisplayName === '') {
      alert('please add tenantdisplayName')
      return
    }

    if (environment === '') {
      alert('please add environment')
      return
    }

    const tenant: PostTenant = {
      tenantDisplayname: tenantDisplayName,
      tenantName: tenantName,
      environment: environment
    }

    callApiWithToken({
      endpoint: `/api/subscription/${Subscription?.id}/tenant`,
      method: 'POST', // Your request payload
      body: tenant
    })
      .then((data) => {
        OnCreated(data)
        OnClosed()
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
      <Modal
        isOpen={IsOpen}
        width='50%'
        onClose={() => OnClosed()}
        hasHeaderBorder={true}
        headerTitle={`Add Tenant in ${Subscription?.customerName}`}
        height={'60%'}
        onConfirm={handleAddTenant}
        variant='prompt'
        labelConfirm='Save'
      >
        <Container isVertical>
          <TextFieldWrapper labelText='Tenant Name'>
            <TextField placeholder='Tenant Name' value={tenantName} onChange={(e) => setTenantName(e.target.value)} />
          </TextFieldWrapper>

          <TextFieldWrapper labelText='Tenant Display Name'>
            <TextField placeholder='Tenant Display Name' value={tenantDisplayName} onChange={(e) => setTenantDisplayName(e.target.value)} />
          </TextFieldWrapper>
          <TextFieldWrapper labelText='Enviroment'>
            <Autocomplete onChange={(e) => setEnvironment(e.value)} options={environmentOptions} />
          </TextFieldWrapper>
        </Container>
      </Modal>
  )
}

export default AddTenant
