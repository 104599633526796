import React, { useState } from 'react'
import { PostSubscription, Subscription } from '../../Interfaces/Types'
import Modal from '@embracesbs/component-modal'
import TextFieldWrapper from '@embracesbs/component-textfieldwrapper/TextFieldWrapper'
import TextField from '@embracesbs/component-textfield/TextField'
import callApiWithToken from '../../Utilities/ApiService/ApiService'

interface AddSubscriptionProps {
  IsOpen: boolean
  OnClosed: () => void
  OnAdded: (subscription: Subscription) => void
}

const AddSubscription: React.FC<AddSubscriptionProps> = ({ IsOpen, OnClosed, OnAdded }) => {
  const [subscriptionName, setSubscriptionName] = useState('')

  const handleAddSubscription = () => {
    const subscription: PostSubscription = {
      customerName: subscriptionName
    }

    callApiWithToken({
      endpoint: `/api/subscription`,
      method: 'POST', // Your request payload
      body: subscription
    })
      .then((data) => {
        OnAdded(data)
        OnClosed()
      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
      <Modal
        isOpen={IsOpen}
        width='50%'
        onClose={() => OnClosed()}
        hasHeaderBorder={true}
        headerTitle='Add Subscription'
        height={'35%'}
        onConfirm={handleAddSubscription}
        variant='prompt'
        labelConfirm='Save'
      >
        <TextFieldWrapper labelText='Subscription Name'>
          <TextField placeholder='Subscription Name' onChange={(e) => setSubscriptionName(e.target.value)} />
        </TextFieldWrapper>
      </Modal>
  )
}

export default AddSubscription
