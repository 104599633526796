import React, { useMemo, useState } from 'react'
import Text from '@embracesbs/component-text'
import TabGroup from '@embracesbs/component-tabgroup/TabGroup'
import { Tenant, TabId, Subscription } from '../../Interfaces/Types'
import TenantDeploymentSettings from './TenantDeploymentSettings'
import TenantMonitoring from './TenantMonitoring'
import FeatureFlag from './FeatureFlag'
import AuditLog from './AuditLog'
import Maintenance from './Maintenance'
import Notifications from './Notifications'
import Settings from './Settings'
import Breadcrumbs from './Breadcrumbs'

interface TenantDetailsProbs {
  selectedTenant?: Tenant
  selectedSubscription?: Subscription
  updateTenant: (tenant: Tenant) => void
  onSelectedSubscription: (subscriptionId: string) => void
  onSelectedTenant: (tenant: Tenant | undefined) => void
  isSearch: boolean
}

const TenantDetails: React.FC<TenantDetailsProbs> = ({ 
  selectedTenant, 
  selectedSubscription,
  updateTenant, 
  onSelectedSubscription, 
  onSelectedTenant,
  isSearch
}) => {
  const [selectedTab, setSelectedTab] = useState(1)
  const handleClick = (id: number) => {
    setSelectedTab(id)
  }

  const renderDetailsContent = useMemo(() => {
    switch (selectedTab) {
      case TabId.DeploytimeSetting:
        return (
        <TenantDeploymentSettings
          selectedTenant={selectedTenant}
          updateTenant={updateTenant}/>)
      case TabId.FeatureFlags:
        return <FeatureFlag tenant={selectedTenant} />
      case TabId.Maintenance:
        return <Maintenance tenant={selectedTenant} />
      case TabId.Notifications:
        return <Notifications tenant={selectedTenant} />
      case TabId.Monitoring:
        return <TenantMonitoring tenant={selectedTenant} />
      case TabId.AuditLog:
        return <AuditLog tenant={selectedTenant} />
      case TabId.Settings:
        return <Settings tenant={selectedTenant} updateTenant={updateTenant} />
      default:
        return <div>Not yet implemented</div>
    }
  }, [selectedTab, selectedTenant, updateTenant])

  const tabs = [
    {
      id: TabId.DeploytimeSetting,
      text: 'Deploytime Setting'
    },
    {
      id: TabId.FeatureFlags,
      text: 'Feature Flags'
    },
    {
      id: TabId.Maintenance,
      text: 'Maintenance'
    },
    {
      id: TabId.Notifications,
      text: 'Notifications'
    },
    {
      id: TabId.Monitoring,
      text: 'Monitoring'
    },
    {
      id: TabId.AuditLog,
      text: 'Audit Log'
    },
    {
      id: TabId.Settings,
      text: 'Tenant Properties Settings'
    }
  ]
  if (isSearch)
    return (
      <Text align='center' textColor='grey'>
        Select 'details' of a subscription or a tenant for more details
      </Text>
    )
  else if (!selectedSubscription)
    return (
      <Text align='center' textColor='grey'>
        Select 'details' of a subscription for the tenants list
      </Text>
    )
  else if (!selectedTenant) {
    return (
      <Text align='center' textColor='grey'>
        Select 'details' of a tenant for deployment settings to show up
      </Text>
    )
  }

  if (selectedTenant.deployStatus === 'initializing') {
    return (
      <Text align='center' textColor='grey'>
        Tenant {selectedTenant.tenantName} is initializing to view the progress press PipelineUrl link{' '}
      </Text>
    )
  }

  return (
    <>
      <Text textStyle='h6'>{selectedTenant.tenantDisplayname}</Text>
      <Breadcrumbs
        selectedTenant={selectedTenant}
        selectedSubscription={selectedSubscription}
        onSelectedSubscription={onSelectedSubscription}
        onSelectedTenant={onSelectedTenant}
      />

      <br></br>
      <TabGroup onTabClick={handleClick} tabs={tabs} activeKey={1} />
      <br></br>
      <br></br>
      {renderDetailsContent}
    </>
  )
}

export default TenantDetails
