import React, { useState } from "react"
import Button from "@embracesbs/component-button"
import { Subscription, SubscriptionWithTenants, Tenant } from "../../Interfaces/Types"
import Container from "@embracesbs/component-container/Container";
import TextField from "@embracesbs/component-textfield";
import AddSubscription from "../Subscriptions/AddSubscription";
import AddTenant from "../Tenants/AddTenant";
import Button2 from "@embracesbs/component-button2";
import Icon from "@embracesbs/component-icon";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "@embracesbs/helpers";

interface DashboardNavigationMenuProps {
  selectedSubscription: Subscription | undefined
  onSelectedSubscription: (subscription: Subscription | undefined) => void
  onSelectedTenant: (tenant: Tenant | undefined) => void
  searchValue: string
  setSearchValue: (searchValue: string) => void
  subscriptionsWithTenants?: SubscriptionWithTenants[]
  setSearchSubscriptions: (subscriptions: Subscription[]) => void
  setSearchTenants: (tenants: Tenant[]) => void
  setIsSearch: (isSearch: boolean) => void
  addNewSubscription: (newsubscription: Subscription) => void
  addNewTenant: (newtenant: Tenant) => void
}

const DashboardNavigationMenu: React.FC<DashboardNavigationMenuProps> = ({
  selectedSubscription,
  onSelectedSubscription,
  onSelectedTenant,
  searchValue,
  setSearchValue,
  subscriptionsWithTenants,
  setSearchSubscriptions,
  setSearchTenants,
  setIsSearch,
  addNewSubscription,
  addNewTenant
}) => {
  const [newSubscriptionOpen, setNewSubscriptionOpen] = useState(false)
  const [newTenantOpen, setNewTenantOpen] = useState(false)
  const navigate = useNavigate()
  const handleBack = () => {
    navigate('/subscriptions', { replace: true })
    onSelectedTenant(undefined)
    onSelectedSubscription(undefined)
  }

  const handleNewSubscription = () => {
    setNewSubscriptionOpen(!newSubscriptionOpen)
  }

  const handleNewTenant = () => {
    setNewTenantOpen(!newTenantOpen)
  }

  const handleNewSubscriptionCreate = (subscription: Subscription) => {
    addNewSubscription(subscription)
    navigate(`/subscriptions/${subscription.id}`, { replace: true })
  }

  const handleNewTenantCreate = (tenant: Tenant) => {
    addNewTenant(tenant)
    navigate(`/subscriptions/${tenant.subscriptionId}/tenant/${tenant.id}}`, { replace: true })
  }

  const handleSearch = (cs: string) => {
    const trimmedSearch = cs.trim().toLowerCase();

    // Early exit if search is empty
    if (!trimmedSearch) {
      setSearchValue('')
      setIsSearch(false)
      setSearchTenants([])
      setSearchSubscriptions([])
      return
    }

    setSearchValue(cs)
    setIsSearch(true)
    navigate('/subscriptions', { replace: true })
    onSelectedSubscription(undefined)
    onSelectedTenant(undefined)

    // Flatten tenants and subscriptions in one loop
    const combinedTenants: Tenant[] = []
    const subscriptions: Subscription[] = []

    subscriptionsWithTenants?.forEach((subscription: SubscriptionWithTenants) => {
      subscriptions.push(subscription.subscription)
      combinedTenants.push(...subscription.tenants)
    })

    // Filter based on search string (case-insensitive)
    const filteredSubscriptions = subscriptions.filter((subscription) =>
      subscription.customerName.toLowerCase().includes(trimmedSearch)
    )
    const filteredTenants = combinedTenants.filter((tenant) =>
      tenant.tenantName.toLowerCase().includes(trimmedSearch)
    )

    // Set state after filtering
    setSearchSubscriptions(filteredSubscriptions)
    setSearchTenants(filteredTenants)
  }

  const onSearch = useDebouncedCallback(handleSearch, 300);

  const renderAddButton = () => {
    return (<Button
      iconName='Add'
      iconSize={20}
      maxWidth='20rem'
      onClick={selectedSubscription !== undefined ? handleNewTenant : handleNewSubscription}
      text=''
      tooltip={selectedSubscription !== undefined ? 'Add Tenant' : 'Add Subscription'}
      type='submit'
      variant='round'
    />
    )
  }

  const renderBackButton = () => {
    if (selectedSubscription !== undefined) {
      return (<Button2
        leftElement={<Icon iconName="ArrowLeft1" size={16} />}
        maxWidth='20rem'
        onClick={handleBack}
        text=''
        tooltip='Add Tenant'
        type='submit'
        variant='contained'
      />
      )
    } else { return }
  }

  const renderAddTenantButton = () => {
    return <AddTenant
      OnCreated={(data) => handleNewTenantCreate(data)}
      Subscription={selectedSubscription}
      OnClosed={handleNewTenant}
      IsOpen={newTenantOpen}
    />
  }

  const renderAddSubscriptionButton = () => {
    return <AddSubscription
      OnClosed={handleNewSubscription}
      IsOpen={newSubscriptionOpen}
      OnAdded={(data) => handleNewSubscriptionCreate(data)}
    />
  }

  const renderSearchField = () => {
    return <TextField
      isSearchMode
      placeholder='Search for a subscription or tenant'
      onChange={(content) => onSearch(content.target.value)}
      onClear={() => onSearch('')}
      value={searchValue}
      type='text'
      variant='default'
    />
  }

  return (
    <>
      <Container>
        {renderBackButton()}
        {renderSearchField()}
        {renderAddButton()}
      </Container>
      <br></br>
      <br></br>
      {renderAddSubscriptionButton()}
      {renderAddTenantButton()}
    </>
  )
};

export default DashboardNavigationMenu