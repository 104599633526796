import React from 'react'
import ListItem from '@embracesbs/component-listitem'
import List from '@embracesbs/component-list/List'
import Button2 from '@embracesbs/component-button2/Button2'
import { Subscription } from '../../Interfaces/Types'
import Container from '@embracesbs/component-container/Container'
import Icon from '@embracesbs/component-icon'
import Text from '@embracesbs/component-text'
import { itemBackgroundColor, itemColor } from '../../Utilities/helpers/helpers'
import Divider from '@embracesbs/component-divider'

interface SubscriptionListProps {
  subscriptions: Subscription[]
  selectedSubscription: Subscription | undefined
  onSelectedSubscription: (subscription: Subscription | undefined) => void
  isSearch: boolean
  isRetrievingData: boolean
}

const SubscriptionList: React.FC<SubscriptionListProps> = ({
  selectedSubscription,
  subscriptions,
  onSelectedSubscription,
  isSearch,
  isRetrievingData
}) => {
  if (subscriptions === undefined || selectedSubscription !== undefined) {
    return null
  }
  if (subscriptions.length > -1 || (isSearch && selectedSubscription === undefined)) {
    if (subscriptions.length < 1) {
      return (
        <>
          <Divider
            size="100%"
            spacing={{
              bottom: 20,
              top: 0
            }}
            style={{ background: '#f2f2f4', borderColor: '#416eec' }}
            text="Subscriptions" />
          {!isRetrievingData ? <Text
            content={"No subscriptions found"}
            style={{ textAlign: 'center' }}
            textColor='grey'>
          </Text> :
            (<Icon
              color={itemColor(undefined, false)}
              iconName="EmailActionSync1"
              size={16}
              style={{
                width: '100%',
                margin: '0 9px 0 0 ',
                animation: 'spin 1s linear infinite'
              }} />)
          }<br></br>
        </>
      )
    }
  }

  return (
    <>
      <Divider
        size="100%"
        spacing={{
          bottom: 20,
          top: 0
        }}
        style={{ background: '#f2f2f4', borderColor: '#416eec' }}
        text="Subscriptions"
      />
      <List ariaLabel='Subscription List'>
        {subscriptions.map((item) => (
          <ListItem
            id={item.id}
            key={item.id}
            icon={{
              iconName: 'Building1Filled',
              size: 16,
              color: itemColor(undefined, false)
            }}
            style={{
              border: '1px solid ' + itemColor(undefined, false),
              margin: '0 0 6px 0',
              background: itemBackgroundColor(false)
            }}
            title={item.customerName}
            preset='oneLineIcon'
            actions={
              <Container>
                <Button2
                  buttonSize="30px"
                  leftElement={<Icon
                    color='#416eec'
                    iconName="TaskOverview"
                    size={10} />
                  }
                  tabIndex={-1}
                  onClick={() => onSelectedSubscription(item)}
                  text="details"
                  type="button"
                  variant="subtle" />
              </Container>
            }
          />
        ))}
      </List>
      <br></br>
    </>
  )
}

export default SubscriptionList
