import React, { useCallback, useEffect, useState } from 'react'
import PageLayout from '../Components/PageLayout/PageLayout'
import TenantList from '../Components/Tenants/TenantList'
import callApiWithToken from '../Utilities/ApiService/ApiService'
import TenantDetails from '../Components/Tenants/TenantDetails'
import { Subscription, SubscriptionWithTenants, Tenant } from '../Interfaces/Types'
import TenantExtendedDetails from '../Components/Tenants/TenantExtendedDetails'
import SubscriptionList from '../Components/Subscriptions/SubscriptionList'
import DashboardNavigationMenu from '../Components/DashboardNavigation/Menu'
import SubscriptionExtendedDetails from '../Components/Subscriptions/SubscriptionExtendedDetails'
import { useNavigate, useParams } from 'react-router-dom'

const Dashboard: React.FC = () => {
  const { subscriptionid, tenantid } = useParams<{ subscriptionid: string, tenantid: string }>();
  const history = useNavigate()
  const [subscriptionsWithTenants, setSubscriptionsWithTenants] = useState<SubscriptionWithTenants[]>([])

  const [selectedSubscription, setSelectedSubscription] = useState<Subscription>()
  const [selectedTenant, setSelectedTenant] = useState<Tenant>()
  
  const [searchSubscriptions, setSearchSubscriptions] = useState<Subscription[]>([])
  const [searchTenants, setSearchTenants] = useState<Tenant[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [isSearch, setIsSearch] = useState(false)
  
  const REFRESH_RATE_IFNOACTIVITY = 60000; // 60 sec
  const [lastActivityTime, setLastActivityTime] = useState(Date.now())
  const [reRender, setReRender] = useState(Date.now())
 
  const [isRetrievingData, setIsRetrievingData] = useState<boolean>(true)

  useEffect(() => {
    setIsRetrievingData(true)
    callApiWithToken({
      endpoint: '/api/subscription',
      method: 'GET' // Your request payload
    })
      .then((data) => {
        setSubscriptionsWithTenants(data)
        setIsRetrievingData(false)    
      })
      .catch((error) => {
        setIsRetrievingData(false)
        console.error(error)
      })
  }, [reRender])

  useEffect(() => {
    // Set a timeout to trigger the re-render after inactivity
    const inactivityTimer = setTimeout(() => {
      if (Date.now() - lastActivityTime >= REFRESH_RATE_IFNOACTIVITY) {
        setReRender(Date.now()); // This could be any state update to trigger re-render after inactivity
      }
    }, REFRESH_RATE_IFNOACTIVITY);

    // Cleanup timeout on effect cleanup or when activity happens
    return () => {
      clearTimeout(inactivityTimer);
    };
  }, [lastActivityTime]); // Re-run this effect when the lastActivityTime changes

  const resetInactivityTimeout = useCallback(() => {
    setLastActivityTime(Date.now()); // Update the time of last interaction
  }, []);
  
  useEffect(() => {
    const handleUserActivity = () => {
      resetInactivityTimeout(); // Reset the inactivity timer on user interaction
    };

    // Event listeners for user interactions
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('click', handleUserActivity);

    // Cleanup listeners on unmount
    return () => {
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('click', handleUserActivity);
    };
  }, [resetInactivityTimeout]);

  useEffect(() => {
    if (subscriptionsWithTenants && Object.keys(subscriptionsWithTenants).length !== 0) {      
      if(subscriptionid && tenantid) {
        setSelectedTenantBasedOnId(tenantid)
      }
      else if (subscriptionid) {
        setSelectedSubscriptionBasedOnId(subscriptionid)
      }
    }
  }, [subscriptionsWithTenants, subscriptionid, tenantid])

  const AddNewSubscription = (subscription: Subscription) => {
    setSubscriptionsWithTenants((prevItems) => [...prevItems, {subscription: subscription, tenants: []}]);
  }

  const AddNewTenant = (tenant: Tenant) => {
    if (tenant.subscriptionId === selectedSubscription?.id) {
      setSubscriptionsWithTenants((prevItems) =>
        prevItems.map((item) => {
          if (item.subscription.id === selectedSubscription.id) {
            return {
              ...item,
              tenants: [...item.tenants, tenant], // Add the new tenant to the tenants array
            };
          }
          return item; // Keep other subscriptions unchanged
        })
      );
    }
  };
  
  const updateTenant = (tenant: Tenant) => {
    setSubscriptionsWithTenants(
      subscriptionsWithTenants.map((subscriptionWithTenant) => {
        if (subscriptionWithTenant.subscription === selectedSubscription) {
          return {
            ...subscriptionWithTenant,
            tenants: subscriptionWithTenant.tenants.map((t) =>
              t === tenant ? { ...tenant } : t
            ),
          };
        }
        return subscriptionWithTenant;
      }))
  }

  const setSelectedSubscriptionBasedOnId = (subsciptionId: string | undefined) => {
    let subscription = subscriptionsWithTenants.find((item) => item.subscription.id === subsciptionId)?.subscription;
    setSelectedSubscription(subscription)
  }

  const setSelectedTenantBasedOnId = (tenantId: string | undefined) => {
    const tenant = subscriptionsWithTenants.map((subscription) => subscription.tenants).flat()
      .find((tenant) => tenant.id === tenantId);

    if (tenant !== undefined) {
      setSelectedSubscriptionBasedOnId(tenant.subscriptionId)
      setSelectedTenant(tenant)
    }
  }

  const setTenantRoute = (tenant: Tenant | undefined) => {
    tenant !== undefined ?
      history(`/subscriptions/${tenant?.subscriptionId}/tenant/${tenant?.id}`, { replace: true })
      : setSelectedSubscriptionAsRoute()
  }

  const setSelectedSubscriptionAsRoute = () => {
    selectedSubscription !== undefined ? history(`/subscriptions/${selectedSubscription.id}`, { replace: true }) 
    : history(`/subscriptions`, { replace: true })
  }

  return (
    <PageLayout
      section1Content={
        <>
          <DashboardNavigationMenu
            addNewTenant={AddNewTenant}
            addNewSubscription={AddNewSubscription}
            selectedSubscription={selectedSubscription}
            onSelectedSubscription={setSelectedSubscription}
            onSelectedTenant={setSelectedTenant}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            subscriptionsWithTenants={subscriptionsWithTenants}
            setSearchSubscriptions={setSearchSubscriptions}
            setSearchTenants={setSearchTenants}
            setIsSearch={setIsSearch}
          /> 
          <SubscriptionList
            isRetrievingData={isRetrievingData}
            isSearch={isSearch}
            selectedSubscription={selectedSubscription}
            subscriptions={isSearch ? searchSubscriptions : subscriptionsWithTenants.map((item) => item.subscription)}
            onSelectedSubscription={(subscription) => {
              setSearchValue('')
              setIsSearch(false)
              setSearchTenants([])
              setSearchSubscriptions([])
              history(`/subscriptions/${subscription?.id}`, { replace: true })
            }}
            />         
          <TenantList
            tenants={isSearch ? searchTenants : subscriptionsWithTenants.find((item) => item.subscription === selectedSubscription)?.tenants || []}
            selectedSubscription={selectedSubscription}
            selectedTenant={selectedTenant}
            onSelectedTenant={(tenant) => {
              setSearchValue('')
              setIsSearch(false)
              setSearchTenants([])
              setSearchSubscriptions([])
              setTenantRoute(tenant)
            }}
            isSearch={isSearch}
            updateTenant={(tenant) => {
              updateTenant(tenant)}}
            />
        </>
      }
      section2Content={
        <TenantDetails
          selectedTenant={selectedTenant}
          selectedSubscription={selectedSubscription}
          onSelectedSubscription={setSelectedSubscriptionBasedOnId}
          onSelectedTenant={setSelectedTenant}
          updateTenant={(tenant) => {
            updateTenant(tenant)}}
          isSearch={isSearch}
        />
      }
      section3Content={ selectedTenant ? <TenantExtendedDetails tenant={selectedTenant} /> : <SubscriptionExtendedDetails subscription={selectedSubscription} />}
    />
  )
}

export default Dashboard
