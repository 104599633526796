import React, { useEffect, useState } from 'react'
import Text from '@embracesbs/component-text'
import { Tenant } from '../../Interfaces/Types'
import callApiWithToken from '../../Utilities/ApiService/ApiService'
import TextFieldWrapper from '@embracesbs/component-textfieldwrapper/TextFieldWrapper'
import TextField from '@embracesbs/component-textfield'
import Container from '@embracesbs/component-container/Container'
import Button2 from '@embracesbs/component-button2/Button2'
import Loader from '../Loader/Loader'

interface tenantSettingsProps {
  tenant?: Tenant
  updateTenant: (tenant: Tenant) => void
}

const Settings: React.FC<tenantSettingsProps> = ({ tenant, updateTenant }) => {

  const [selectedTenant, setSelectedTenant] = useState<Tenant>()

  useEffect(() => {
    if (tenant) {
      setSelectedTenant(tenant)
    }
  }, [tenant])

  const handleTenantConfigurationChange = (name: string, value: any) => {
    setSelectedTenant((prevTenant) => {
      if (!prevTenant) return prevTenant
      return {
        ...prevTenant, 
        [name]: value
      } as Tenant 
    })
  }
  
  const handleUpdate = () => {
    callApiWithToken({
      endpoint: `/api/tenant/${tenant?.id}`,
      method: 'PUT',
      body: selectedTenant
    })
      .then(() => {
        if(selectedTenant) {
          updateTenant(selectedTenant)
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  if (tenant) {
    return (
      <>
        <Container justify='flex-end'>
          <Button2
            buttonRadius='8px'
            buttonSize='40px'
            text={'Update Tenant Properties'}
            onClick={() => handleUpdate()}
            variant='contained'
          />
        </Container>
        <Container justify='flex-start'>
          <Text textStyle='h6'>Tenant Properties</Text>
        </Container>
        <Container>
          <TextFieldWrapper labelText={'Display name'}>
            <TextField
              value={tenant?.tenantDisplayname}
              onChange={(e) => handleTenantConfigurationChange('tenantDisplayname', e.target.value)}
              type='text'
              variant='default'
            />
          </TextFieldWrapper>
        </Container>
      </>
    )
  } else {
    return <Loader ringCollor='lightgrey' viewbox='-75 10 200 50' text='Loading tenant deploy configuration'></Loader>
  }
}

export default Settings
